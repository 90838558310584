import React from 'react'

const ValuesContent = ({ mission, vision, img1, img2, img3 }) => {

  const whyChooseUs = [
    {
      tittle:'18 years of experience in the industry'
    },
    {
      tittle:'Licensed and insured professionals'
    },
    {
      tittle:'Attention to detail in every cleaning job'
    },
    {
      tittle:'Competitive pricing'
    },
    {
      tittle:'Availability for emergency and scheduled services'
    },
    {
      tittle:'Commitment to safety and quality workmanship'
    }
]

  return (
    <section className='w-4/5 mx-auto grid grid-cols-1 md:grid-cols-3 gap-20 md:gap-10 py-20'>

      <div
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <picture>
          <img
            src={img1}
            alt='images mission'
            className='w-full h-[250px] object-cover rounded-sm pb-5'
          />
        </picture>
        <div className='text-center'>
          <h3>Mission</h3>
          <p>{mission}</p>
        </div>
      </div>

      <div
        className='rounded-sm h-auto md:h-full bg-cover bg-center bg-no-repeat relative before:absolute before:w-full before:h-full before:bg-[#ffffff82]'
        style={{
          backgroundImage: `url("${img2}")`
        }}
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <div className='relative bgOverlayColor w-[90%] mx-auto h-full flex items-center -mt-5 rounded-sm'>
          <div className='text-center text-white p-5 '>
            <h3 className='capitalize'>vision</h3>
            <p>{vision}</p>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <picture>
          <img
            src={img3}
            alt='images why choose us'
            className='w-full h-[250px] object-cover rounded-sm pb-5'
          />
        </picture>
        <div className='text-start'>
          <h3 className='capitalize text-center'>why choose us</h3>
          {
            whyChooseUs.map((item, index) =>{
              return(
                <li key={index}>{item.tittle}</li>
              )
              
            })
          }
        </div>
      </div>

    </section>
  )
}

export default ValuesContent